
@import './base';

@mixin pageContainer {
  height: calc(100vh - #{$footer-height});
}

@mixin headerContainer {
  height: $header-height;
  padding-left: 66px;
  padding-top: 25px;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 2px 4px 0 #00000080;
  z-index: 1;
}

@mixin headerTitle {
  @include text;
  margin-top: 0px;
  margin-bottom: 20px;
  color: $offDark;
  font-size: 24px;
  font-weight: 600;
}