@import '~vzc-client-common/src/shared/globals';
@import '~vzc-client-common/src/shared/colours';

.actions {
    display: flex;
    flex-direction: row;

    >* {
        margin-right: 15px;
    }
}

.nameLabel {
    @include text;
    color: $rowPrimaryDark;
    font-weight: 500;
}

