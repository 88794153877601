@import '~vzc-client-common/src/shared/colours';

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: #e1e2e1;
}

// .App-logo {
//   animation: App-logo-spin infinite 20s linear;
//   pointer-events: none;
// }

.vzBodyContainer {
  // min-height: calc(100vh - 76px - 54px - 370px);
  min-height: calc(100vh - 76px - 54px); // without footer links
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0 auto;
}

.vzContentContainer {
  height: 100%;
  border-width: 200px;
  border-color: white;
  justify-content: center;
  min-width: 1024px;
}

.App-link {
  color: #61dafb;
}

// :any-link {
//   all: unset;
// }

a {
  color: inherit;
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .vzBodyContainer {
    max-width: 768px;
    min-width: 300px;
    min-height: calc(100vh - 76px - 105px);
  }

  .vzContentContainer {
    max-width: 768px;
    min-width: 300px;
    min-height: calc(100vh - 76px - 105px);
  }
}