$header-height: 190px;
//$content-height: 100vh;
//$total-page-height: $header-height + $content-height;

$footer-height: 0;
$total-footer-height: 0;
$content-header-height: 50px;

$sideBarWidth: 280px;

@mixin tab {
    height: calc(100vh - #{$footer-height + $header-height});
    width: calc(100vw - #{$sideBarWidth});
    background-color: #f5f5f6;
    position: relative;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    padding: 20px;
}