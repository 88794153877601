@import "~vzc-client-common/src/shared/colours";
@import "~vzc-client-common/src/shared/globals";

.vzLoginContainer {
  margin-top: 120px;
  width: 436px;
  margin: 120px auto 0 auto;
  height: 475px;
  background-color: #f5f5f6;
  margin-bottom: 120px;
}

.vzLoginHeader {
  height: 102px;
  background-color: #3f3f3f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vzLoginHeaderTitle {
  width: 358px;
  height: 30px;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.vzLoginBody {
  color: black;
  margin-top: 34px;
  text-align: left;
  padding: 0 69px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

.vzLoginBodyTitle {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2c2c2c;
}

.vzLoginHR {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #555555;
  margin: 1em 0;
  padding: 0;
}

.vzLoginBodyText {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2c2c2c;
}

.vzBoldText {
  font-weight: bold;
}

.vzLogInTextInput {
  width: 300px;
  height: 45px;
  margin-top: 1px;
  margin-bottom: 25px;
  font-size: 15px;
}

.vzLogInErrorMessage {
  margin-top: -20px;
  color: red;
  margin-bottom: 5px;
}

.vzLogInErrorMessageTotal {
  // margin-top: 5px;
  color: red;
  margin: 20px auto 0 auto;
  text-align: center;
}

.vzLogInProceedButton {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: $primaryOrange;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 30px;
}

@media only screen and (max-width: 414px) {
  .vzLoginContainer {
    width: calc(100vw - 25px);
    margin: 25px auto 25px auto;
    max-width: 414px;
    height: 440px;
  }

  .vzLogInHeader {
    width: calc(100vw - 25px);
    height: 131px;
    background-color: #3f3f3f;
  }

  .vzLoginBody {
    width: calc(100vw - 25px);
    padding: 0 18px 0 18px;
  }

  .vzLogInTextInput {
    width: calc(85vw);
  }

  .vzAgreementText {
    width: 77vw;
  }

  .vzLogInProceedButton {
    margin: 30px auto 0 auto;
    width: 88vw;
  }
}

@media only screen and (max-width: 375px) {
  .vzLoginBody {
    width: calc(100vw - 25px);
    padding: 0 12px 0 12px;
  }
}

.link {
  margin-top: 20px;
  text-align: center;
  @include text;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}
