.vzFooterLink {
    padding-right: 8px;
    padding-left: 8px;
    margin-left: 15px;
    padding-top: 10px;
    font-size: 14px;
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    .vzInvisible {
      display: none;
    }
  }