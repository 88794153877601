/* Creates an animated loading indicator spinner. */
@mixin loadingIndicator($selector, $size: 30px, $loadingColor: #555555) {
  $totalSize: $size;

  #{$selector} {
    width: $totalSize;
    height: $totalSize;
    @content;

    width: $totalSize;
    height: $totalSize;
    //margin: 0 auto;
    $max: 8;
    @for $i from 1 through $max {
      $step: $i / $max;
      $angle: ($step * 360deg);
      :nth-child(#{$i}) {
        -webkit-transform: rotate($angle);
        -ms-transform: rotate($angle);
        transform: rotate($angle);
      }

      $animDelay: -0.7s + ($step * 0.8s);
      :nth-child(#{$i}):before {
        -webkit-animation-delay: $animDelay;
        animation-delay: $animDelay;
      }
    }

    * {
      width: $totalSize;
      height: $totalSize;
      position: absolute;
      left: 0;
      top: 0;
    }

    $orange: #dd8121;
    *:before {
      content: "";
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: $loadingColor;
      border-radius: $totalSize;
      -webkit-animation: sk-circleBounceDelay 0.7s infinite ease-in-out both;
      animation: sk-circleBounceDelay 0.7s infinite ease-in-out both;
    }
  }
}

@-webkit-keyframes sk-circleBounceDelay {
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
